body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  background: /**rgb(50, 56, 63)  rgb(103, 0, 0)*/ linear-gradient(to bottom, rgb(120,0,0), rgb(60, 0, 0));
  background-attachment: fixed;
  color: #fff;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mainBody {
  display: flex;
}

.leftColumn {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 0px;
  flex-wrap: wrap;
}

.rightColumn {
  position: relative;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-right: 1%;  
  
  
}

h1, h2, h3, p {
  text-shadow: 2px 2px 5px rgba(0,0,0,0.6);

}

h1 {
  text-align: center;
}

.cardbutton {
  margin: 0px;
  margin-top: 10px;
  margin-right: 2px;
  width: 55px;
  border: 2px solid #000;
  border-radius: 5px;
  display: inline-block;
  padding: 0px;
  position: relative;
}

.cardImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  padding: 0px;
  margin: 0px;
  filter: brightness(100%)
}

.cardbutton :hover {
  filter: brightness(80%);
}

.cardbutton.selected {
  margin: 0px;
  margin-top: 10px;
  margin-right: 2px;
  width: 55px;
  border: 2px solid #000;
  border-radius: 5px;
  display: inline-block;
  padding: 0px;
  position: relative;
  filter: brightness(50%);
}

.Selectedcard {
  margin: 0px;
  margin-top: 10px;
  margin-right: 2px;
  width: 5.77vw;
  border: 2px solid #000;
  border-radius: 5px;
  display: inline-block;
  padding: 0px;
  position: relative;
}

.Placecard {
  margin: 0px;
  margin-top: 10px;
  width: 5.77vw;
  border: 2px solid #000;
  border-radius: 5px;
  display: inline-block;
  padding: 0px;
  position: relative;
  filter: brightness(30%);
}

.displayBoard {
  margin-right: 20px;
  text-align: center;
  width: 100%;

}

.displayHand {
  margin-right: 1vw;
  margin-left: 1vw;
}

.displayHand h3 {
  text-align: center;
}

.directions {
  margin: 0px;
  margin-left: 20px;
}

.spacer {
  width: 100%;
  height: 50px;
}

.resetButton {
  margin: 0px;
  margin-top: 10px;
  margin-right: 2px;
  width: 55px;
  border: 2px solid #000;
  border-radius: 5px;
  display: inline-block;
  padding: 0px;
  position: relative;
  font-family: inherit;
  color: rgb(103, 0, 0);
  background-color: #fff;
  font-weight: bold;

}

.resetButton:hover {
  filter: brightness(80%);
}

.resetDiv {
  display: flex;
  justify-content: center;
}

.resetContainer {
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 960px) {
  .mainBody {
    flex-direction: column;
  }

  .leftColumn {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .rightColumn {
    padding-right: 0px;
  }

  .cardbutton {
    width: 70px;
  }

  .cardbutton.selected {
    width: 70px;
  }

  .Selectedcard {
    width: 70px;
  }

  .Placecard {
    width: 70px;
  }

  .displayBoard {
    margin-right: 0px;
  }

  .displayHand {
    margin-right: 0px;
    margin-left: 20px;
  }

  .resetButton {
    width: 40px;
  }

  .hide {
    display: none;
  }

  .directions {
    margin-right: 20px;
  }
}

@media screen and (max-width: 500px) {
  .mainBody {
    flex-direction: column;
  }

  .leftColumn {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .rightColumn {
    padding-right: 0px;
  }

  .cardbutton {
    width: 7%;
    margin-top: 2px;
  }

  .cardbutton.selected {
    width: 7%;
    margin-top: 2px;
  }

  .Selectedcard {
    width: 40px;
  }

  .Placecard {
    width: 40px;
  }

  .displayBoard {
    margin-right: 0px;
  }

  .displayHand {
    margin-right: 0px;
    margin-left: 20px;
  }

  .resetButton {
    width: 40px;
  }

  .hide {
    display: none;
  }
}







